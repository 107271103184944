* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.form-wraper {
  display: flex;
  justify-content: center;
  height: 100vh;
  background-image: linear-gradient(
  135deg,
  hsl(24deg 100% 61%) 0%,
  hsl(24deg 100% 68%) 10%,
  hsl(24deg 100% 76%) 21%,
  hsl(24deg 100% 84%) 31%,
  hsl(24deg 100% 92%) 42%,
  hsl(0deg 0% 100%) 52%,
  hsl(112deg 82% 89%) 62%,
  hsl(112deg 81% 78%) 72%,
  hsl(112deg 81% 68%) 82%,
  hsl(112deg 81% 58%) 91%,
  hsl(112deg 86% 48%) 100%
);
}

.cardlog {
  margin-top: 52px;
  width: 350px;
  height: 526px;
  padding: 1.9rem 1.2rem;
  text-align: center;
  background: #ffbf00;
}

/*Inputs*/
.field {
  margin-top: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5em;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: .5em 1em;
}

.input-icon {
  height: 1em;
  width: 1em;
  fill: #c79900;
}

.input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #0c0b0b;
  font-family: monospace;
}

/*Text*/
.title {
  margin-bottom: 1rem;
  font-size: 1.5em;
  font-weight: 500;
  color: #f5f5f5;
  text-align: center;
  font-family: ui-sans-serif;
}

/*Buttons*/
.btnlog {
  margin: 2rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: .8em;
  text-transform: uppercase;
  padding: 0.6em 1.2em;
  background-color: #ffeba7;
  color: #5e6681;
  box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
  transition: all .3s ease-in-out;
}
.btnlog:active{
  transform: scale(0.93);
}

.btn-link {
  color: #f5f5f5;
  display: block;
  font-size: .75em;
  transition: color .3s ease-out;
}

/*Hover & focus*/
.field input:focus::placeholder {
  opacity: 0;
  transition: opacity .3s;
}

.btn:hover {
  background-color: #5e6681;
  color: #ffeba7;
  box-shadow: 0 8px 24px 0 rgb(16 39 112 / 20%);
}

.btn-link:hover {
  color: #ffeba7;
}

/* regi style */
.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: .75em;
  padding-right: .75em;
}

.card-registration .select-arrow {
  top: 13px;
}

.reg-wrapper {
  display: flex;
  justify-content: center;
}

.form-wrapper {
  margin-top: 40px;
  /* width: 75%; */
  height: 90%;
  padding: 1.9rem 1.2rem;
  background: #ffbf00;
  border-radius: 20px;
  text-align: center;

}

label {
  color: white;
  margin-right: 25px;
  font-size: large;
}

span {
  color: white;
  margin-right: 10px;
}

.p-container {
  margin-top: 10px;
  padding: 5px 4px;
  font-size: 1.2rem;
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.Register-container {
  font-size: larger;
  color: white;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  cursor: pointer;
  margin-bottom: 200px;
}

.Register-container:hover {
  color: #d3d3d3;
  background-color:#2fe511;
  border-radius: 10px;
}

.Register-container a {
  text-decoration: none;
  color: white !important;
}

img {
  height: 100%;
}

.img-wraper {
  height: 100px;
}

.img-container {
  height: 100px;
}

.contact-page {
  width: 100%;
  height: 70%;
  position: relative;
  top: 20%;
}

.input {
  border-radius: 10px;
  border-color: #ffc107;
  padding: 4px;
}

.input-placeholder {
  font-family: cursive;
}

.message-handle {
  font-size: 1.5rem;
  padding: 5px;
}

.btn-registration {
  margin: 2rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: .8em;
  text-transform: uppercase;
  padding: 0.6em 1.2em;
  background-color: #ffeba7;
  color: #5e6681;
  box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
  transition: all .3s ease-in-out;
}

/* -------------------------content-button----------------------------------------------------------------- */
.myButton {
  color: white;
  font-size: 18px;
  line-height: 18px;
  padding: 9px;
  border-radius: 9px;
  font-family: Georgia, serif;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  background-image: linear-gradient(to right, rgb(28, 110, 164) 0%, rgb(35, 136, 203) 50%, rgb(20, 78, 117) 100%);
  border: 2px solid rgb(28, 110, 164);
  display: inline-block;
}

.myButton:hover {
  background: #1C6EA4;
}

.myButton:active {
  background: #144E75;
}
/* ------------------------------------------LOG OUT BUTTON */
.button-2{
margin: 13px;
padding: 10px;
text-align: center;
font-size: 16px;
text-transform: uppercase;
cursor: pointer;
background: #1ba12f;
border-radius: 10px;
border: none;
color: #fff;
font-weight: bold;
letter-spacing: 1px;
}
.button-2:hover {
background: linear-gradient(88.18deg, #ff7b31 0.34%, #f669b5 121.79%);
} 
.select{
  width: 40%;
  border: none;
  border-radius: 5px;
  padding: 3px 2px 4px 2px;
}
/* ----------------------------------------registration page----------------------------------------------- */
.reg-img{
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}